* {
  font-family: "Manrope", sans-serif;
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
  background-color: var(--chakra-colors-gray-50);
  scroll-behavior: smooth;
}
